<script>
// import {SimpleBar} from "simplebar-vue3";
import {authComputed} from "@/store/helpers";
import ModalUserModify from '../components/user/modify'

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      locale: null,
      configActive: false,
      messageActive: false,
      user: null,
      locales: [
        {
          flag: require("@/assets/images/flags/kr.png"),
          language: "kr",
          title: "한국어",
        },
        {
          flag: require("@/assets/images/flags/us.png"),
          language: "en",
          title: "English",
        },
      ]
    };
  },
  components: {
    // SimpleBar,
    ModalUserModify
  },
  mounted() {
    // 언어 설정
    const locale = localStorage.getItem('locale')
    this.locale = locale ? JSON.parse(locale) : this.locales[0]
    this.$i18n.locale = this.locale.language

    // 유저
    this.user = this.$store.getters['auth/user']
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      document.location.href= '/'
    },
    // 메뉴토글
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    // 우측 사이드바 토글
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    // 전체화면
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    // 언어 변경
    changeLocale(locale) {
      console.log('***언어변경')
      this.locale = locale
      this.$i18n.locale = locale.language
      localStorage.setItem('locale', JSON.stringify(locale))
    },
    openUserModify() {
      this.$refs['ModalUserModify'].open(this.$store.getters['auth/user'])
    }
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box ps-1 pe-1">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="$store.getters['layout/logoImageSmall']" width="30"/>
            </span>
            <span class="logo-lg">
              <img :src="$store.getters['layout/logoImage']" alt height="50"/>
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light p-1">
            <span class="logo-sm p-0">
              <img :src="$store.getters['layout/logoImageSmall']" width="30"/>
            </span>
            <span class="logo-lg">
              <img :src="$store.getters['layout/logoImage']" alt height="30"/>
            </span>
          </router-link>
        </div>

        <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item" @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <!--
        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon">
            <span class="rounded rounded-1 ps-2 pe-2 bg-primary text-white">유효배팅: 0</span>
          </button>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon">
            <span class="rounded rounded-1 ps-2 pe-2 bg-success text-white">회원승: 0</span>
          </button>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon">
            <span class="rounded rounded-1 ps-2 pe-2 bg-info text-white">W/L: 0</span>
          </button>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon">
            <span class="rounded rounded-1 ps-2 pe-2 bg-pink text-white">환수율: 0%</span>
          </button>
        </div>
        //-->

        <!-- 언어 //-->
        <div class="dropdown d-inline-block" v-if="locale">
          <button type="button" class="btn header-item waves-effect" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img id="header-lang-img" :src="locale.flag" :alt="locale.title" height="16">
          </button>
          <div class="dropdown-menu dropdown-menu-end">

            <!-- item-->
            <a href="javascript:void(0);" @click="changeLocale(locale)" class="dropdown-item notify-item language" v-for="(locale, i) in locales" :key="`locale-${i}`" :data-lang="locale.language">
              <img :src="locale.flag" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{ locale.title }}</span>
            </a>
          </div>
        </div>

        <!-- 전체 화면 //-->
        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen" style="color: #ffffff;"></i>
          </button>
        </div>

        <!-- 메세지
        <b-dropdown right menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="bx bx-bell" :class="{'bx-tada': messageActive}" @mouseover="messageActive=true" @mouseout="messageActive=false"></i>
            <span class="badge bg-danger rounded-pill">
              {{$t("navbar.dropdown.notification.badge")}}
            </span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">{{
                    $t("navbar.dropdown.notification.subtext")
                  }}</a>
              </div>
            </div>
          </div>
          <SimpleBar style="max-height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                      class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    메세지 제목
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      메세지 내용
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      2022-08-01 12:30:00
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                      class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    메세지 제목
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      메세지 내용
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      2022-08-01 12:30:00
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                      class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    메세지 제목
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      메세지 내용
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      2022-08-01 12:30:00
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                      class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    메세지 제목
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      메세지 내용
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      2022-08-01 12:30:00
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </SimpleBar>
          <div class="p-2 border-top d-grid">
            <a
                class="btn btn-sm btn-link font-size-14 text-center"
                href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown>
        //-->
        <!-- 나의 정보 //-->
        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template v-slot:button-content v-if="user">
            <span v-html="user.avatar" style="display: inline-block; width: 16px;"></span>
            <span class="d-none d-xl-inline-block ms-1" style="color: #ffffff;">
              <div v-if="user">
                {{ user.nickname }}
              </div>
              <div v-else>Guest</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>

          <!-- item-->
          <b-dropdown-item>
            <a href="javascript:void(0)" @click="openUserModify">
              <span>
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                {{ $t("navbar.dropdown.mypage.list.modify") }}
              </span>
            </a>
          </b-dropdown-item>

          <b-dropdown-item>
            <router-link to="/setting/api">
              <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
              {{ $t("navbar.dropdown.mypage.list.settings") }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <button type="button" class="dropdown-item text-danger" @click="logout">
            <i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.mypage.list.logout") }}
          </button>
        </b-dropdown>

      </div>
    </div>
    <ModalUserModify ref="ModalUserModify" />
  </header>
</template>
