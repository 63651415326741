<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import {menuItems} from "./menu";

import ModalUserModify from '../components/user/modify'

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      lt: 0,
      menuItems: menuItems,
      menuData: null,
    };
  },
  components: {
    ModalUserModify
  },
  mounted: function () {
    this.lt = new Date().getTime()
    if (document.getElementById("side-menu")) new MetisMenu("#side-menu");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let matchingMenuItem = null;
    const paths = [];

    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }

    const itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            const childAnchor = parent3.querySelector(".has-arrow");
            const childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },
    openUserModify() {
      this.$refs['ModalUserModify'].open(this.$store.getters['auth/user'])
    }
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
      <div class="ps-3 pe-3 pt-3 text-center border-bottom border-top border-secondary">
          <p><span class="badge bg-light p-2 ps-4 pe-4 w-90 bg-soft text-light" style="font-size: 1em;" v-if="$store.getters['auth/user']"><i class="bx bx-wallet" style="font-size: 1.2em;">&nbsp;{{ $store.getters['auth/user'].cash.floatVal(2).formatThousands() }}</i></span></p>
      </div>
      <div class="ps-3 pe-3 pt-3 text-center border-bottom border-top border-secondary">
          <div class="pb-3 text-center"><a href="javascript:void(0)" @click="openUserModify"><span class="badge bg-light p-2 ps-3 pe-3 w-40 bg-soft text-white" style="font-size: 1em;">{{$t('정보수정')}}</span></a><a href="/setting/api" class=""><span class="badge bg-light ms-1 p-2 ps-3 pe-3 w-40 bg-soft text-success" style="font-size: 1em;">{{$t('API설정')}}</span></a></div>
      </div>
    <ul id="side-menu" class="metismenu list-unstyled" v-if="$store.getters['auth/user']">
      <template v-for="item in menuItems">
        <li v-if="($store.getters['auth/user'].type === 'admin' || !item.onlyAdmin) && item.isTitle" :key="item.id" class="menu-title" v-html="$t(item.label)"></li>
        <li v-if="($store.getters['auth/user'].type === 'admin' || !item.onlyAdmin) && !item.isTitle && !item.isLayout" :key="item.id">
          <a v-if="hasItems(item)" :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }" class="is-parent" href="javascript:void(0);">
            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
            <span v-html="$t(item.label) "></span>
            <span v-if="item.badge" :class="`badge rounded-pill bg-${item.badge.variant} float-end`">{{ $t(item.badge.text) }}</span>
          </a>

          <router-link v-if="!hasItems(item)" :to="item.link + '?_t=' + lt" class="side-nav-link-ref">
            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
            <span v-html="$t(item.label)"></span>
            <span v-if="item.badge" :class="`badge rounded-pill bg-${item.badge.variant} float-end`">{{ $t(item.badge.text) }}</span>
          </router-link>

          <ul v-if="hasItems(item)" aria-expanded="false" class="sub-menu">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <template v-if="($store.getters['auth/user'].type === 'admin') || (!subitem.checkRole || (subitem.roles && subitem.roles.indexOf($store.getters['auth/user'].level) > -1))">
                <router-link v-if="!hasItems(subitem)" :to="subitem.link + '?_t=' + lt" class="side-nav-link-ref" v-html="$t(subitem.label)"></router-link>
                <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" v-html="$t(subitem.label)"></a>
                <ul v-if="hasItems(subitem)" aria-expanded="false" class="sub-menu mm-collapse">
                  <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                    <router-link :to="subSubitem.link" class="side-nav-link-ref" v-html="$t(subSubitem.label)"></router-link>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
    <ModalUserModify ref="ModalUserModify" />
  <!-- Sidebar -->
</template>
